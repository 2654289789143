import React from "react"
import Layout from "../components/js/layout"
import SEO from "../components/js/seo"

import "../components/css/page/book.css"

class BookPage extends React.Component {

  state = { 
    tagsOn: [],
    tagsOpen: false 
  }

  render () {
    return (
      <Layout page="book">
        <SEO title="얄코 책"/>
        <section className="books">
          <ul>
          </ul>
        </section>


      </Layout>
    )
  }

  componentDidMount () {
    window.location.replace('https://www.yalco.kr/#books')
  }

}


// const ShopsPage = () => {
// }

export default BookPage